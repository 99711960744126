import { OfferingInitialDrawdown } from 'types/Portfolio/OfferingInititalDrawdown'
import { SerializableOfferingInitialDrawdown } from 'types/Serializable'
import { deserializeBigNumber, serializeBigNumber } from 'backend/serialization/BigNumber'

export function serializeOfferingInitialDrawdown(
  instance: OfferingInitialDrawdown | null,
): SerializableOfferingInitialDrawdown {
  if (!instance) {
    return null
  }

  if (instance.type === 'currency') {
    return {
      amount: serializeBigNumber(instance.amount),
      type: 'currency',
    }
  }

  return {
    amount: instance.amount,
    type: 'percentage',
  }
}

export function deserializeOfferingInitialDrawdown(
  json: SerializableOfferingInitialDrawdown,
): OfferingInitialDrawdown | null {
  if (!json) {
    return null
  }

  if (json.type === 'currency') {
    return {
      amount: deserializeBigNumber(json.amount),
      type: 'currency',
    }
  }

  return {
    amount: json.amount,
    type: 'percentage',
  }
}
