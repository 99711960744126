import { ReactNode } from 'react'
import { Row } from 'components/atoms/Row'
import { zIndexes } from 'styles'
import styled from 'styled-components'

interface Props {
  title: string
  rightSide?: ReactNode
  desktopSticky?: boolean
}

export const DashboardHeader = ({ title, rightSide, desktopSticky }: Props) => {
  return (
    <DashboardHeaderContainer desktopSticky={desktopSticky}>
      <h2>{title}</h2>
      <RightSide>{rightSide}</RightSide>
    </DashboardHeaderContainer>
  )
}

const DashboardHeaderContainer = styled(Row)<{ desktopSticky?: boolean }>`
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.White};
  padding: 32px 0 24px;
  margin-top: -24px;
  z-index: ${zIndexes.stickyHeader};

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    position: ${({ desktopSticky }) => (desktopSticky ? 'initial' : 'sticky')};
    padding: ${({ desktopSticky }) => (desktopSticky ? '32px 0 0' : '32px 0')};
    margin-top: ${({ desktopSticky }) => (desktopSticky ? '0' : '-32px')};
  }
`

const RightSide = styled(Row)`
  gap: 12px;
`
