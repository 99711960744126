import { BigNumber } from 'ethers'
import { SerializableBigNumber } from 'types/Serializable'

export function serializeBigNumber(bigNumber: BigNumber): SerializableBigNumber
export function serializeBigNumber(bigNumber: BigNumber | undefined): SerializableBigNumber | null
export function serializeBigNumber(bigNumber: BigNumber | undefined): SerializableBigNumber | null {
  return bigNumber ? bigNumber.toJSON() : null
}

export function deserializeBigNumber(bigNumber: SerializableBigNumber): BigNumber
export function deserializeBigNumber(bigNumber: SerializableBigNumber | null): BigNumber | undefined
export function deserializeBigNumber(bigNumber: SerializableBigNumber | null): BigNumber | undefined {
  return bigNumber ? BigNumber.from(bigNumber) : undefined
}
