import { deserializeTranche, serializeTranche } from 'backend/serialization/Tranche'
import { deserializeToken, serializeToken } from 'backend/serialization/Token'
import { serializeBigNumber } from 'backend/serialization/BigNumber'
import { AssetOffering } from 'types/Portfolio/StructuredOffering'
import { BigNumber } from 'ethers'
import { SerializableAssetOffering } from 'types/Serializable'
import { CurrencyValue } from '@usedapp/core'
import { deserializeOfferingCheckpoint, serializeOfferingCheckpoint } from 'backend/serialization/OfferingCheckpoint'
import {
  deserializeOfferingInitialDrawdown,
  serializeOfferingInitialDrawdown,
} from 'backend/serialization/OfferingInitialDrawdown'

export function serializeAssetOffering(instance: AssetOffering): SerializableAssetOffering {
  return {
    ...instance,
    latestCheckpoint: serializeOfferingCheckpoint(instance.latestCheckpoint),
    tranches: instance.tranches.map(serializeTranche),
    asset: serializeToken(instance.asset),
    startDate: instance.startDate.toJSON(),
    endDate: instance.endDate.toJSON(),
    creationDate: instance.creationDate.toJSON(),
    startDeadline: instance.startDeadline.toJSON(),
    minimumSize: serializeBigNumber(instance.minimumSize),
    totalAccruedInterest: serializeBigNumber(instance.totalAccruedInterest),
    totalInterestDisbursed: serializeBigNumber(instance.totalInterestDisbursed),
    outstandingPrincipal: serializeBigNumber(instance.outstandingPrincipal.value),
    outstandingAssets: serializeBigNumber(instance.outstandingAssets.value),
    outstandingInterest: serializeBigNumber(instance.outstandingInterest.value),
    capitalRaisingStrategy: instance.capitalRaisingStrategy,
    minimumInvestorCommitment: serializeBigNumber(instance.minimumInvestorCommitment),
    initialDrawdown: serializeOfferingInitialDrawdown(instance.initialDrawdown),
  }
}

export function deserializeAssetOffering(json: SerializableAssetOffering): AssetOffering {
  const asset = deserializeToken(json.asset)
  return {
    ...json,
    latestCheckpoint: deserializeOfferingCheckpoint(json.latestCheckpoint),
    tranches: json.tranches.map(deserializeTranche),
    asset,
    startDate: new Date(json.startDate),
    endDate: new Date(json.endDate),
    creationDate: new Date(json.creationDate),
    startDeadline: new Date(json.startDeadline),
    minimumSize: BigNumber.from(json.minimumSize),
    totalAccruedInterest: BigNumber.from(json.totalAccruedInterest),
    totalInterestDisbursed: BigNumber.from(json.totalInterestDisbursed),
    outstandingPrincipal: new CurrencyValue(asset, BigNumber.from(json.outstandingPrincipal)),
    outstandingAssets: new CurrencyValue(asset, BigNumber.from(json.outstandingAssets)),
    outstandingInterest: new CurrencyValue(asset, BigNumber.from(json.outstandingInterest)),
    capitalRaisingStrategy: json.capitalRaisingStrategy,
    minimumInvestorCommitment: BigNumber.from(json.minimumInvestorCommitment),
    initialDrawdown: deserializeOfferingInitialDrawdown(json.initialDrawdown),
  }
}
