import { ChainId, CurrencyFormatOptions, Token } from '@usedapp/core'
import { TOKEN_ADDRESSES } from './addresses'
import { SupportedChainId } from 'constants/chain'

interface TokenArgs {
  name: string
  symbol: string
  decimals: number
  chainId: SupportedChainId
  formattingOptions?: Partial<CurrencyFormatOptions>
}

export function createToken({ name, symbol, decimals, chainId, formattingOptions }: TokenArgs): Token {
  const token = TOKEN_ADDRESSES[symbol][chainId]
  if (!token) {
    throw new Error('token not supported')
  }

  return new Token(name, symbol, chainId, token, decimals, formattingOptions)
}

export function createChainTokens(chains: SupportedChainId[], tokenArgs: Omit<TokenArgs, 'chainId'>) {
  return chains.reduce(
    (acc, chainId) => ({ ...acc, [chainId]: createToken({ ...tokenArgs, chainId }) }),
    {} as { [key in ChainId]: Token },
  )
}

export const USDC = createChainTokens([ChainId.Mainnet, ChainId.Sepolia], {
  name: 'USD Coin',
  symbol: 'USDC',
  decimals: 6,
})

export const TUSD = createChainTokens([ChainId.Mainnet], {
  name: 'TrueUSD',
  symbol: 'TUSD',
  decimals: 18,
})

export const USDT = createChainTokens([ChainId.Mainnet], {
  name: 'Tether',
  symbol: 'USDT',
  decimals: 6,
})

export function getTokensByChain(tokens: Record<SupportedChainId, Token>[], chainId: SupportedChainId): Token[] {
  return tokens.map((token) => token[chainId]).filter((token) => !!token)
}

const ALL_TOKENS = [USDC, TUSD, USDT] as Record<SupportedChainId, Token>[]

export const TOKENS: Record<SupportedChainId, Token[]> = {
  [ChainId.Mainnet]: getTokensByChain(ALL_TOKENS, ChainId.Mainnet),
  [ChainId.Sepolia]: getTokensByChain(ALL_TOKENS, ChainId.Sepolia),
}
