import { Tranche } from 'types'
import { deserializeBigNumber, serializeBigNumber } from 'backend/serialization/BigNumber'

import { SerializableTranche } from 'types/Serializable'

export function serializeTranche(instance: Tranche): SerializableTranche {
  return {
    ...instance,
    ceiling: serializeBigNumber(instance.ceiling),
    minimumInvestment: serializeBigNumber(instance.minimumInvestment),
    managerFeeRate: instance.managerFeeRate ?? null,
  }
}

export function deserializeTranche(json: SerializableTranche): Tranche {
  const bigNumber = json.ceiling
  return {
    ...json,
    ceiling: deserializeBigNumber(bigNumber),
    minimumInvestment: deserializeBigNumber(json.minimumInvestment),
  }
}
