import { Token } from '@usedapp/core'
import { SerializableToken } from 'types/Serializable'
import { convertToToken } from 'backend/data/Token'

export function serializeToken(instance: Token): SerializableToken {
  return {
    chainId: instance.chainId,
    address: instance.address,
    decimals: instance.decimals,
    ticker: instance.ticker,
    name: instance.name,
  }
}

export function deserializeToken(json: SerializableToken): Token {
  return convertToToken(json)
}
