import { SerializableOffering } from 'types/Serializable'
import { deserializeCreditOffering, serializeCreditOffering } from 'backend/serialization/CreditOffering'
import { deserializeAssetOffering, serializeAssetOffering } from 'backend/serialization/AssetOffering'
import { AssetOffering, CreditOffering } from 'types/Portfolio/StructuredOffering'

export function serializeOffering(instance: AssetOffering | CreditOffering): SerializableOffering {
  if (instance._type === 'AssetOffering') return serializeAssetOffering(instance)

  return serializeCreditOffering(instance)
}

export function deserializeOffering(json: SerializableOffering): AssetOffering | CreditOffering {
  if (json._type === 'CreditOffering') return deserializeCreditOffering(json)

  return deserializeAssetOffering(json)
}
