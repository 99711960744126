import { SupportedChainId } from 'constants/chain'
import { ChainId } from '@usedapp/core'

type ContractAddresses = Partial<Record<SupportedChainId, string>>
type Addresses = Record<string, ContractAddresses>

export const TOKEN_ADDRESSES: Addresses = {
  USDC: {
    [ChainId.Mainnet]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    [ChainId.Sepolia]: '0x82c5b09Bff125661f887Af06E38138FEca23F52F',
  },
  USDT: {
    [ChainId.Mainnet]: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
  TUSD: {
    [ChainId.Mainnet]: '0x0000000000085d4780B73119b644AE5ecd22b376',
  },
}
