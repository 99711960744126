import { constants } from 'ethers'
import { LiveDataOffering } from 'types/Portfolio'
import { Offering } from 'types/Portfolio/StructuredOffering'

import { useTotalAssets } from './useTotalAssets'
import { useLiquidAssets } from './useLiquidAssets'
import { CurrencyValue } from '@usedapp/core'

export const useLiveDataPortfolios = (portfolios: Offering[]): LiveDataOffering[] => {
  const firstPortfolioChainId = portfolios[0]?.chainId
  const isHomogeneousPortfolioList = portfolios.every(({ chainId }) => chainId === firstPortfolioChainId)
  // eslint-disable-next-line no-console
  if (!isHomogeneousPortfolioList) console.warn('Expected all portfolios to be from the same chain')

  const totalAssets = useTotalAssets(portfolios, firstPortfolioChainId)
  const liquidAssets = useLiquidAssets(portfolios, firstPortfolioChainId)

  return portfolios.map((portfolio, index) => ({
    ...portfolio,
    totalAssets: new CurrencyValue(portfolio.asset, totalAssets[index] ?? constants.Zero),
    liquidAssets: new CurrencyValue(portfolio.asset, liquidAssets[index] ?? constants.Zero),
  }))
}
