import { SerializableToken } from 'types/Serializable'
import { Token } from '@usedapp/core'
import { getTokenByAddress } from 'utils'

export const convertToToken = (rawToken: SerializableToken): Token => {
  const { chainId, address } = rawToken

  let token = getTokenByAddress(chainId, address)

  if (!token) {
    const { name, ticker, decimals } = rawToken
    token = new Token(name, ticker.toUpperCase(), chainId, address, decimals)
  }

  return token
}
