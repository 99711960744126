import { ChainId, Token } from '@usedapp/core'
import { TOKENS } from 'constants/tokens'
import { SupportedChainId } from 'constants/chain'

export function transformToObject(array: Token[]): Record<string, Token> {
  return array.reduce((acc, curr) => ({ ...acc, [curr.address.toLowerCase()]: curr }), {})
}

const TOKENS_MAP: Record<SupportedChainId, Record<string, Token>> = {
  [ChainId.Mainnet]: transformToObject(TOKENS[ChainId.Mainnet]),
  [ChainId.Sepolia]: transformToObject(TOKENS[ChainId.Sepolia]),
}

export function getTokenByAddress(chainId: SupportedChainId, tokenAddress: string): Token | undefined {
  try {
    return TOKENS_MAP[chainId][tokenAddress.toLowerCase()]
  } catch {
    return undefined
  }
}
