import { deserializeBigNumber, serializeBigNumber } from 'backend/serialization/BigNumber'

import { SerializableOfferingCheckpoint } from 'types/Serializable'
import { StructuredOfferingCheckpoint } from 'types/Portfolio/StructuredOffering'

export function serializeOfferingCheckpoint(
  instance: StructuredOfferingCheckpoint | null,
): SerializableOfferingCheckpoint | null {
  if (!instance) {
    return null
  }

  return {
    ...instance,
    totalAssets: serializeBigNumber(instance.totalAssets),
    totalSupply: serializeBigNumber(instance.totalSupply),
    createdAt: instance.createdAt.toJSON(),
  }
}

export function deserializeOfferingCheckpoint(
  json: SerializableOfferingCheckpoint | null,
): StructuredOfferingCheckpoint | null {
  if (!json) {
    return null
  }

  return {
    ...json,
    totalAssets: deserializeBigNumber(json.totalAssets),
    totalSupply: deserializeBigNumber(json.totalSupply),
    createdAt: new Date(json.createdAt),
  }
}
