import { SerializablePotentialOffering, SerializablePotentialOfferingWithInterestedIn } from 'types/Serializable'
import { formatTargetAPY, PotentialOffering, PotentialOfferingWithInterestedIn } from 'types'
import { deserializeToken, serializeToken } from 'backend/serialization/Token'
import { BigNumber } from 'ethers'
import { serializeBigNumber } from 'backend/serialization/BigNumber'
import { CurrencyValue } from '@usedapp/core'
import { dollar } from 'constants/currency'
import {
  deserializeOfferingInitialDrawdown,
  serializeOfferingInitialDrawdown,
} from 'backend/serialization/OfferingInitialDrawdown'

export function serializePotentialOffering(instance: PotentialOffering): SerializablePotentialOffering {
  return {
    ...instance,
    asset: serializeToken(instance.asset),
    startDate: instance.startDate.toJSON(),
    endDate: instance.endDate.toJSON(),
    creationDate: instance.creationDate.toJSON(),
    startDeadline: instance.startDeadline.toJSON(),
    minimumSize: serializeBigNumber(instance.minimumSize),
    minimumInvestment: serializeBigNumber(instance.minimumInvestment),
    targetFundraise: serializeBigNumber(instance.targetFundraise),
    managerFee: instance.managerFee ?? 0,
    minimumInvestorCommitment: serializeBigNumber(instance.minimumInvestorCommitment),
    initialDrawdown: serializeOfferingInitialDrawdown(instance.initialDrawdown),
  }
}

export function deserializePotentialOffering(json: SerializablePotentialOffering): PotentialOffering {
  return {
    ...json,
    asset: deserializeToken(json.asset),
    startDate: new Date(json.startDate),
    endDate: new Date(json.endDate),
    creationDate: new Date(json.creationDate),
    startDeadline: new Date(json.startDeadline),
    minimumSize: BigNumber.from(json.minimumSize),
    minimumInvestment: BigNumber.from(json.minimumInvestment),
    targetFundraise: BigNumber.from(json.targetFundraise),
    targetApy: formatTargetAPY(json.expectedEquityRate),
    minimumInvestorCommitment: BigNumber.from(json.minimumInvestorCommitment),
    initialDrawdown: deserializeOfferingInitialDrawdown(json.initialDrawdown),
  }
}

export function serializePotentialOfferingWithInterestedIn(
  instance: PotentialOfferingWithInterestedIn,
): SerializablePotentialOfferingWithInterestedIn {
  const { interestedIn, ...offeringPart } = instance
  const serializedOfferingPart = serializePotentialOffering(offeringPart)
  return {
    ...serializedOfferingPart,
    interestedIn: {
      numberOfInvestors: interestedIn.numberOfInvestors,
      commitment: interestedIn.commitment.value.toJSON(),
    },
  }
}

export function deserializePotentialOfferingWithInterestedIn(
  json: SerializablePotentialOfferingWithInterestedIn,
): PotentialOfferingWithInterestedIn {
  const { interestedIn, ...offeringPart } = json
  const serializedOfferingPart = deserializePotentialOffering(offeringPart)
  return {
    ...serializedOfferingPart,
    interestedIn: {
      numberOfInvestors: interestedIn.numberOfInvestors,
      commitment: new CurrencyValue(dollar, BigNumber.from(interestedIn.commitment)),
    },
  }
}
