import { TableColumn } from 'components/organisms/Datatable/types'
import { Img } from 'components/atoms/Image'
import { CurrencyStatistic, Statistic } from 'components/molecules/Statistic'
import { CurrencyValue } from '@usedapp/core'
import { ManagerInfo, ManagerName, PortfolioTitle } from '../PortfolioCard'
import { Text } from 'components/atoms/Text'
import { TimeLeft } from 'components/molecules/TimeLeft'
import styled from 'styled-components'
import { TableCellColumn } from 'components/atoms/Table'
import { PortfolioStatusBadge } from 'components/Portfolios/PortfolioDetails/PortfolioStatusBadge'
import { DashboardPortfolio } from 'components/Dashboards/hooks/useDashboardPortfolios'
import { formatDate } from 'utils/formatting/formatDate'
import { isPotentialOffering, PotentialOffering, PotentialOfferingWithInterestedIn } from 'types/Portfolio'
import { getManagerImage } from 'domain/offering/getManagerImage'
import {
  getEstimatedEndDate,
  getManagerName,
  getManagerProfileUrl,
  getMinimumInvestment,
  getOfferingPreviewUrl,
  getPortfolioTerm,
  isEvergreenPortfolio,
  isInCapitalFormationPeriod,
  sortByStartTime,
} from 'domain/offering'
import { isAssetOffering, Offering } from 'types/Portfolio/StructuredOffering'
import { addSeconds } from 'date-fns'
import { getOfferingApy } from 'domain/offering/getOfferingApy'

type PortfolioColumn = TableColumn<Offering | PotentialOffering>
type DashboardPortfolioColumn = TableColumn<DashboardPortfolio>
type PotentialWithInterestColumn = TableColumn<PotentialOfferingWithInterestedIn>

export const nameColumn: PortfolioColumn = {
  name: 'Name',
  selector: ({ name }) => name,
  cell: ({ name }) => (
    <PortfolioTitle variant="body2" bold color="dark">
      {name}
    </PortfolioTitle>
  ),
  href: getOfferingPreviewUrl,
}

export const statusColumn: PortfolioColumn = {
  name: 'Status',
  selector: ({ status }) => status,
  cell: (portfolio) => (
    <StatusCellColumn>
      <PortfolioStatusBadge status={portfolio.status} />
      {isPotentialOffering(portfolio) || isEvergreenPortfolio(portfolio.endDate) ? null : (
        <TimeLeft endDate={isInCapitalFormationPeriod(portfolio) ? portfolio.startDeadline : portfolio.endDate} />
      )}
    </StatusCellColumn>
  ),
  href: getOfferingPreviewUrl,
  side: 'right',
  customSort: sortByStartTime,
}

export const managerColumn: PortfolioColumn = {
  name: 'Manager',
  selector: (portfolio) => getManagerName(portfolio),
  cell: (portfolio) => (
    <ManagerInfo>
      <Img image={getManagerImage(portfolio)} size={20} />
      <ManagerName>{getManagerName(portfolio)}</ManagerName>
    </ManagerInfo>
  ),
  href: getManagerProfileUrl,
}

export const apyColumn: PortfolioColumn = {
  name: 'Est. Average APY',
  selector: (portfolio) => selectPortfolioAPY(portfolio),
  cell: (portfolio) => (
    <Text color="dark" variant="body2" bold>
      {getOfferingApy(portfolio)}
    </Text>
  ),
  href: getOfferingPreviewUrl,
  side: 'right',
}

export const minCommitmentColumn: PortfolioColumn = {
  name: 'Min Commitment',
  selector: ({ name }) => name,
  cell: (portfolio) => {
    const minimumInvestment = getMinimumInvestment(portfolio)
    return (
      <CurrencyStatistic
        value={minimumInvestment && new CurrencyValue(portfolio.asset, minimumInvestment)}
        variant="body2"
      />
    )
  },
  href: getOfferingPreviewUrl,
  side: 'right',
  customSort: (a, b) => {
    const minimumInvestmentA = getMinimumInvestment(a)
    const minimumInvestmentB = getMinimumInvestment(b)
    if (minimumInvestmentB === undefined) {
      return 1
    }
    if (minimumInvestmentA === undefined) {
      return -1
    }
    return minimumInvestmentA.sub(minimumInvestmentB).toNumber()
  },
}

const selectPortfolioAPY = (portfolio: Offering | PotentialOffering) => {
  if (isAssetOffering(portfolio) && portfolio.currentApyInBPS) {
    return portfolio.currentApyInBPS
  }

  return portfolio.targetApy ?? 0
}

const selectPortfolioTermEnd = (portfolio: Offering | PotentialOffering) => {
  if (isPotentialOffering(portfolio)) {
    return addSeconds(new Date(), portfolio.duration)
  }

  return isInCapitalFormationPeriod(portfolio) ? getEstimatedEndDate(portfolio) : portfolio.endDate
}

export const termColumn: PortfolioColumn = {
  name: 'Portfolio Term',
  selector: selectPortfolioTermEnd,
  cell: (portfolio) => (
    <Text color="dark" variant="body2">
      {getPortfolioTerm(portfolio)}
    </Text>
  ),
  href: getOfferingPreviewUrl,
  side: 'right',
}

export const maturityColumn: PortfolioColumn = {
  name: 'Maturity Date',
  selector: selectPortfolioTermEnd,
  cell: (portfolio) => (
    <Text color="dark" variant="body2">
      {formatDate(selectPortfolioTermEnd(portfolio))}
    </Text>
  ),
  href: getOfferingPreviewUrl,
  side: 'right',
}

export const creationColumn: PortfolioColumn = {
  name: 'Creation Date',
  selector: ({ creationDate }) => creationDate,
  cell: ({ creationDate }) => (
    <Text color="dark" variant="body2">
      {formatDate(creationDate)}
    </Text>
  ),
  href: getOfferingPreviewUrl,
  side: 'right',
}

export const assetColumn: DashboardPortfolioColumn = {
  name: 'Asset Under Management',
  selector: ({ totalAssets }) => totalAssets.value,
  cell: ({ asset, totalAssets }) => <CurrencyStatistic value={totalAssets} variant="body2" currency={asset} />,
  href: getOfferingPreviewUrl,
  side: 'right',
}

export const capitalColumn: DashboardPortfolioColumn = {
  name: 'Undeployed Capital',
  selector: ({ undeployedCapital }) => undeployedCapital,
  cell: ({ asset, undeployedCapital }) => (
    <CurrencyStatistic value={new CurrencyValue(asset, undeployedCapital)} variant="body2" currency={asset} />
  ),
  href: getOfferingPreviewUrl,
  side: 'right',
}

const StatusCellColumn = styled(TableCellColumn)`
  align-items: flex-end;
`

export const commitmentColumn: PotentialWithInterestColumn = {
  name: 'Commitment',
  selector: ({ interestedIn }) => interestedIn.commitment.value,
  cell: ({ interestedIn }) => <CurrencyStatistic value={interestedIn.commitment} variant="body2" />,
  href: getOfferingPreviewUrl,
  side: 'right',
}

export const potentialInvestorsColumn: PotentialWithInterestColumn = {
  name: 'Potential Investors',
  selector: ({ interestedIn }) => interestedIn.numberOfInvestors,
  cell: ({ interestedIn }) => <Statistic text={interestedIn.numberOfInvestors} variant="body2" />,
  href: getOfferingPreviewUrl,
  side: 'right',
}
